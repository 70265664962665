var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"responsive",rawName:"v-responsive",value:({
  xsm: function (el) { return el.width <= 400; },
  sm: function (el) { return el.width <= 600; },
  smlg: function (el) { return el.width <= 768; },
  medsm: function (el) { return el.width <= 1024; },
  med: function (el) { return el.width <= 1368; },
  medlg: function (el) { return el.width <= 1600; },
  lgsm: function (el) { return el.width <= 1900; },
  lg: function (el) { return el.width <= 1920; },
  xlg: function (el) { return el.width <= 2560; },
  xxlg: function (el) { return el.width <= 3840; }
}),expression:"{\n  xsm: el => el.width <= 400,\n  sm: el => el.width <= 600,\n  smlg: el => el.width <= 768,\n  medsm: el => el.width <= 1024,\n  med: el => el.width <= 1368,\n  medlg: el => el.width <= 1600,\n  lgsm: el => el.width <= 1900,\n  lg: el => el.width <= 1920,\n  xlg: el => el.width <= 2560,\n  xxlg: el => el.width <= 3840\n}"}],attrs:{"id":"app"}},[_c('sponsorship-slider',{ref:"slider",attrs:{"id":"slider","children":_vm.children,"motCode":_vm.motCode,"utmCode":_vm.utmCode,"isLoading":_vm.isLoading,"sliderConfigs":_vm.sliderConfigs,"sliderConfigured":_vm.sliderConfigured,"languageCode":_vm.languageCode},on:{"loadMoreChildren":_vm.loadMoreChildren}}),_c('div',{staticClass:"swiper-button-prev",style:({ 'display': (_vm.isLoading ? 'none' : 'block') }),attrs:{"slot":"button-prev"},slot:"button-prev"}),_c('div',{staticClass:"swiper-button-next",style:({ 'display': (_vm.isLoading ? 'none' : 'block') }),attrs:{"slot":"button-next"},slot:"button-next"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }