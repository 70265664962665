// polyfill for older browsers
import '@babel/polyfill' // import basic polyfill
import 'isomorphic-fetch' // add fetch polyfill to support vuetify
import 'document-register-element/build/document-register-element' // add polyfill for custom elements
// importing vue
import Vue from 'vue'
import App from './App.vue'
// import custom element capability
import vueCustomElement from 'vue-custom-element'
// import Vue Router
import VueRouter from 'vue-router'
// import Vuetify
import Vuetify from 'vuetify'
// import 'vuetify/dist/vuetify.min.css' // remove vuetify css because it's bleeding out of widget into page
import './assets/vuetify-scoped.scss' // add vuetify CSS that has been sanitized to remove "general" selectors
// import slider and styles
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(Vuetify)
Vue.use(VueAwesomeSwiper)
Vue.use(vueCustomElement)

// initialize router to allow parsing query string params
const router = new VueRouter({
  routes: [{
    path: '/',
    name: 'Main'
  }]
})

App.router = router
// implement Vue app as a custom HTML element
Vue.customElement('sponsorship-widget', App)
