<template>
  <swiper-slide>
    <v-card class="child-card">
      <v-responsive class="justify-center">
        <v-avatar class="child-image-wrapper" :size="208">
          <div class="child-image" v-bind:style="{ 'background-image': 'url(' + child.image + ')' }" :alt="'Image of ' + child.firstname"></div>
        </v-avatar>
        <div class="sponsorship-card-inner">
          <h3 class="child-name">{{ child.firstname }}</h3>
          <v-container class="info-wrapper" align-center>
            <div class="icon-info-block">
              <icon-base class="icon icon-gender" v-if="child.genderCode === 'm'" icon-name="Boy" width="27" height="27"><icon-boy /></icon-base>
              <icon-base class="icon icon-gender" v-if="child.genderCode === 'f'" icon-name="Girl" width="27" height="27"><icon-girl /></icon-base>
              <h6 class="icon-info-title">{{ languageCode === 'en' ? labelText.gender.en : labelText.gender.fr }}: </h6><h5 class="icon-info-info">{{ child.gender }}</h5>
            </div>
            <div class="icon-info-block">
              <icon-base class="icon icon-country" icon-name="World" width="27" height="27"><icon-world /></icon-base>
              <h6 class="icon-info-title">{{ languageCode === 'en' ? labelText.country.en : labelText.country.fr }}: </h6><h5 class="icon-info-info">{{ child.country }}</h5>
            </div>
            <div class="icon-info-block">
              <icon-base class="icon icon-birthday" icon-name="Birthday" width="27" height="27"><icon-birthday /></icon-base>
              <h6 class="icon-info-title">{{ languageCode === 'en' ? labelText.birthday.en : labelText.birthday.fr }}: </h6>
              <h5 v-if="languageCode === 'en'" class="icon-info-info">{{ child.birthdayMonth }} {{ child.birthday }}, {{ child.birthyear }}</h5>
              <h5 v-if="languageCode === 'fr'" class="icon-info-info">{{ child.birthday }} {{ child.birthdayMonth }} {{ child.birthyear }}</h5>
            </div>
          </v-container>
          <v-card-actions class="action-bar justify-center">
            <v-btn class="sponsor-button" flat v-on:click="checkout(child)">{{ languageCode === 'en' ? labelText.sponsor.en : labelText.sponsor.fr }}</v-btn>
          </v-card-actions>
        </div>
      </v-responsive>
    </v-card>
  </swiper-slide>
</template>

<script>
import IconBase from './IconBase.vue'
import IconBirthday from './icons/IconBirthday.vue'
import IconBoy from './icons/IconBoy.vue'
import IconGirl from './icons/IconGirl.vue'
import IconWorld from './icons/IconWorld.vue'

export default {
  components: {
    IconBase,
    IconBirthday,
    IconBoy,
    IconGirl,
    IconWorld
  },
  props: ['child', 'motCode', 'utmCode', 'languageCode'],
  data () {
    return {
      labelText: {
        gender: {
          en: 'Gender',
          fr: 'Sexe'
        },
        country: {
          en: 'Country',
          fr: 'Pays'
        },
        birthday: {
          en: 'Birthday',
          fr: 'Naissance'
        },
        sponsor: {
          en: 'Sponsor Now',
          fr: 'Parrainez'
        }
      }
    }
  },
  methods: {
    checkout: function (child) {
      // default shop link is English store
      let shopLink = 'https://donate.worldvision.ca/pages/bsb?asc=' + child.childid
      if (this.languageCode === 'fr') {
        shopLink = 'https://donner.visionmondiale.ca/pages/bsb?asc=' + child.childid
      }
      let requiredTags = ['month', 'gender', 'childnumber', 'lang', 'projectid', 'mc', 'childid', 'country', 'type', 'transaction_type', 'firstname', 'product_code', 'birthday', 'age']
      for (const [key, value] of Object.entries(child)) {
        if (requiredTags.indexOf(key) > -1) {
          shopLink += '&' + key + '=' + value
        }
      }
      if (this.motCode !== 0) {
        shopLink += '&mc=' + this.motCode + '&type=SP'
      }
      if (this.utmCode !== 0) {
        shopLink += '&utm=' + this.utmCode
      }
      // go to Shopify with the child in cart and pass in mot code
      window.open(shopLink, '_blank')
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.child-card {
  height: 100%;
  border: none !important;
  padding-bottom: 30px !important;
}
.child-image {
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  border: 3px solid #EDEDED;
  background-color: #fafafa;
  border-radius: 50%;
  overflow: hidden;
  -webkit-transition: all ease-in-out 0.25s;
  transition: all ease-in-out 0.25s;
  position: absolute;
  top: 0; // required for IE11 support
  left: 50%;
  width: 200px;
  height: 200px;
  transform-origin: center bottom;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  margin-left: -102px;
  z-index: 4;
}
.child-image-wrapper {
  -webkit-transition: all ease-in-out 0.25s;
  transition: all ease-in-out 0.25s;
  position: absolute;
  left: 50%;
  top: -90px;
  width: 200px;
  height: 200px;
  transform-origin: center bottom;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  margin-left: -102px;
  z-index: 4;
  margin-top: 100px;
}
.child-card:hover .child-image, .child-card:hover .child-image-wrapper {
  transform-origin: center bottom;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.child-name {
  font-size: 28px;
  text-transform: capitalize;
}
.icon {
  margin-right: 8px;
}
.icon-info-block {
  margin: 8px 0;
}
.icon-info-info {
  padding-top: 2px;
  padding-left: 2px;
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  font-weight: normal;
  text-rendering: optimizeLegibility;
  color: #333333;
  text-transform: uppercase;
}
.icon-info-title {
  margin-right: 4px;
  font-size: 17px;
  color: #333333;
  display: inline-block;
  vertical-align: top;
  line-height: 1.68;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
.info-wrapper {
  width: 65%;
  text-align: left;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 102px;
}
.sponsorship-card-inner {
  margin-top: 120px;
  -webkit-transition: border-color ease-in-out 0.25s;
  transition: border-color ease-in-out 0.25s;
  border-color: transparent;
  background: #FFFFFF;
  border-radius: 3px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.15);
  padding: 110px 5px 22px;
  position: relative;
  text-align: center;
  min-height: 200px;
  border: 2px #e0e0e0 solid;
  border-radius: 3px;
}
.sponsorship-card-inner:hover {
  background: #FFFFFF;
  color: #333333;
  border: 3px solid #E7600C;
  padding: 105px 5px 25px;
  color: #000000;
}
.swiper-slide {
    background-position: center;
    background-size: cover;
    min-height: 450px;
  }
.v-avatar {
  margin-bottom: 20px;
}
.v-btn {
  position: relative;
  cursor: pointer;
  -moz-osx-font-smalloothing: grayscale;
  -webkit-font-smalloothing: antialiased;
  background: #0099CC;
  border-radius: 100px;
  border: 1.5px solid transparent;
  color: #FFFFFF !important;
  display: inline-block;
  font-family: "Gill Sans W01 Medium", "Gill Sans MT", Calibri, Arial, sans-serif;
  letter-spacing: 1.5px;
  line-height: 1.5;
  text-align: center;
  text-transform: uppercase;
  -webkit-transition: all ease-in-out 0.25s;
  transition: all ease-in-out 0.25s;
  outline: none;
  font-size: 13px;
  -webkit-appearance: none;
}
.elevation-2 {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

/* 1600 x 900 */
.medlg {
  .info-wrapper {
    width: 66%;
    padding-left: 80px;
    .icon-info-block {
      .icon-info-info {
        padding-top: 1px;
        font-size: 15px;
      }
      .icon-info-title {
        font-size: 15px;
      }
    }
  }
}

/* 1300 x 700 */
.med {
  .info-wrapper {
    width: 78% !important;
    padding-left: 50px;
  }
}

/* 1024 x 768 */
.medsm {
  .info-wrapper {
    width: 90% !important;
    padding-left: 30px;
  }
}

/* large phone */
.smlg {
  .info-wrapper {
    padding-left: 10%;
  }
  @media screen and (pointer: coarse) and (orientation: landscape) {
    .info-wrapper {
      padding-left: 35%;
    }
    .sponsorship-card-inner {
      margin-left: 25px;
      margin-right: 25px;
    }
  }
}
/* medium phone */
.sm {
  @media screen and (pointer: coarse) and (orientation: landscape) {
    .info-wrapper {
      padding-left: 25%;
    }
  }
}

/* small phone */
.xsm {
  .icon-info-info {
      font-size: 15px;
  }
  .icon-info-title {
      width: 60px;
      font-size: 15px;
      color: #333333;
      line-height: 1.68;
  }
}

@media screen and (min-width: 400px) and (max-width: 420px) {
  .sponsorship-card-inner {
    margin-left: 30px;
    margin-right: 30px;
  }
}
</style>
