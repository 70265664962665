<template>
  <div id="app" v-responsive="{
    xsm: el => el.width <= 400,
    sm: el => el.width <= 600,
    smlg: el => el.width <= 768,
    medsm: el => el.width <= 1024,
    med: el => el.width <= 1368,
    medlg: el => el.width <= 1600,
    lgsm: el => el.width <= 1900,
    lg: el => el.width <= 1920,
    xlg: el => el.width <= 2560,
    xxlg: el => el.width <= 3840
  }">
    <sponsorship-slider id="slider" ref="slider" :children="children" :motCode="motCode" :utmCode="utmCode"
      :isLoading="isLoading" :sliderConfigs="sliderConfigs" :sliderConfigured="sliderConfigured"
      @loadMoreChildren="loadMoreChildren" :languageCode="languageCode"></sponsorship-slider>
    <div class="swiper-button-prev" slot="button-prev" v-bind:style="{ 'display': (isLoading ? 'none' : 'block') }">
    </div>
    <div class="swiper-button-next" slot="button-next" v-bind:style="{ 'display': (isLoading ? 'none' : 'block') }">
    </div>
  </div>
</template>

<script>
import { ResponsiveDirective } from 'vue-responsive-components'
import SponsorshipSlider from './components/SponsorshipSlider.vue'
import Client from 'shopify-buy/index.unoptimized.umd'

export default {
  name: 'app',
  props: ['mc', 'collection', 'event', 'number', 'utm', 'spacing', 'cards', 'lang'],
  components: {
    SponsorshipSlider
  },
  directives: {
    responsive: ResponsiveDirective
  },
  data () {
    return {
      children: [],
      client: {},
      collectionId: 0,
      defaultSpecialEventIds: {
        en: 0, // 8846
        fr: 0 // 8843
      },
      domains: {
        en: 'worldvisioncanada1.myshopify.com',
        fr: 'worldvisioncanada1-fr.myshopify.com'
      },
      genderNames: {
        m: {
          en: 'Boy',
          fr: 'GarÇon'
        },
        f: {
          en: 'Girl',
          fr: 'Fille'
        }
      },
      isLoading: true,
      languageCode: 'en',
      monthNames: {
        en: ['None', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        fr: ['None', 'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
      },
      motCode: 0,
      numberChildren: 30,
      cursor: null,
      pageInfo: null,
      overrideSlidesPerView: 0,
      overrideSpaceBetweenSlides: 0,
      productQuery: {},
      sliderConfigs: {
        defaultSlidesPerView: 3,
        defaultSpaceBetweenSlides: 180,
        slidesPerView: 3,
        spaceBetweenSlides: 180
      },
      sliderConfigured: false,
      storefrontAccessTokens: {
        en: 'dbbb5d702e70edf7820d3bec60a13fb2',
        fr: '5092c1a22a3f0cd1ab66d838cff381b7'
      },
      specialEventId: 9368,
      utmCode: 0
    }
  },
  mounted () {
    // determine language
    this.languageCode = this.$route.query.lang || this.lang || this.languageCode
    this.languageCode = this.languageCode.toLowerCase() // force lower-case

    // set up shopify parameters based on language (English default)
    let domain = this.domains.en
    let storefrontAccessToken = this.storefrontAccessTokens.en
    // set specialEventId to default English one
    this.specialEventId = this.defaultSpecialEventIds.en

    // set French storefront params if French is selected
    if (this.languageCode === 'fr') {
      domain = this.domains.fr
      storefrontAccessToken = this.storefrontAccessTokens.fr
      // set specialEventId to default French one
      this.specialEventId = this.defaultSpecialEventIds.fr
    }

    // ovewrite default configuration values with values from URL params OR html embed properties
    this.motCode = parseInt(this.$route.query.mc) || this.mc || this.motCode
    this.collectionId = parseInt(this.$route.query.collection) || this.collection || this.collectionId
    this.specialEventId = parseInt(this.$route.query.event) || this.event || this.specialEventId
    this.numberChildren = parseInt(this.$route.query.numResults) || this.number || this.numberChildren
    this.utmCode = parseInt(this.$route.query.utm) || this.utm || this.utmCode

    // set value for overridden slider configs if entered as HTML params in the widget tags
    this.overrideSlidesPerView = parseInt(this.$route.query.cards) || this.cards || this.overrideSlidesPerView
    this.overrideSpaceBetweenSlides = parseInt(this.$route.query.spacing) || this.spacing || this.overrideSpaceBetweenSlides

    // initialize shopify-buy SDK
    this.client = Client.buildClient({
      domain: domain,
      storefrontAccessToken: storefrontAccessToken,
      appId: '6'
    })

    // Usage example:
    this.getAllProductTags()
      .then((tags) => {
        console.log('All Product Tags:', tags)
      })
      .catch((error) => {
        console.error('Error:', error)
      })
    // get initial batch of children
    // this.loadChildren()

    // wait for div class change before allowing slider update
    var e = document.getElementById('app')
    var observer = new MutationObserver(() => {
      this.updateSliderSize()
    })
    observer.observe(e, {
      attributes: true,
      attributeFilter: ['class'],
      childList: false,
      characterData: false
    })
  },
  methods: {
    getAllProductTags: async function () {
      try {
        this.isLoading = true
        // Define the GraphQL query
        const graphqlQuery = `
            {
              products(query: "tag:special_event_id=${this.specialEventId}", first: ${this.numberChildren}, after: ${this.cursor}, sortKey: TITLE) {
                pageInfo {
                  hasNextPage
                  endCursor
                }
                edges {
                  node {
                    title,                    
                    tags,
                    images(first: 1) {
                      edges {
                        node {
                          src
                        }
                      }
                    }
                  }
                }
              }
            }
            `
        // Create a GraphQL request
        fetch(`https://${this.domains.en}/api/2023-10/graphql.json`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Shopify-Storefront-Access-Token': this.storefrontAccessTokens.en
          },
          body: JSON.stringify({
            query: graphqlQuery
          })
        })
          .then((response) => response.json())
          .then((data) => {
            const products = data.data.products.edges
            this.pageInfo = data.data.products.pageInfo
            if (products.length > 0) {
              this.parseChildInfo(products)
              // update stored query to get next page if needed
              this.productQuery = products[products.length - 1] // get next page will start from last child next time
            }
            this.isLoading = false
            // const allTags = products.map((edge) => edge.node.tags.split(',').map((tag) => tag.trim())).flat();
            // console.log('All Product Tags:', allTags);
          })
          .catch((error) => {
            console.error('Error:', error)
          })
      } catch (error) {
        console.error('Error:', error)
        throw error
      }
    },
    // loadChildren: async function () {

    //   // build Shopify Query - and allow overriding collection to pull from general pool.
    //   // var query String = ''
    //   // if (this.collectionid !== 0) {
    //   //   queryString = `collection_id:'${this.collectionId}' AND tag:'pledge_type:sp'`
    //   //   // allow specifying both collection ID and special event ID
    //   //   if (this.specialEventId !== 0) {
    //   //     queryString = `collection_id:'${this.collectionId}' AND tag:'pledge_type:sp' AND tag:'special_event_id:${this.specialEventId}'`
    //   //   }
    //   // } else {
    //   //   // by default, only specify special event ID tag
    //   //   queryString = `tag:'special_event_id:${this.specialEventId}'`
    //   // }
    //   // this.specialEventId
    //   let query = {
    //     first: this.numberChildren,
    //     query: 'tag:[special_event_id=9347]',
    //     sortBy: 'title'
    //   }
    //   // let query = {
    //   //   first: this.numberChildren,
    //   //   query: queryString,
    //   //   sortBy: 'title'
    //   // }
    //   // get child info through tags, then parse the tags to create child object
    //   // this call requires shopify-buy version 1.10. Tags no longer display as of version 1.11 (currently being fixed by Shopify)
    //   // this.client.product.fetchAll().then(products =>
    //   // {
    //   //   products.forEach(product =>
    //   //   {
    //   //     console.log(`Product ID: ${product.id}, Tags: ${product.tags}`)
    //   //   })
    //   // })
    //   this.client.product.fetchQuery(query).then((products) => {
    //     this.parseChildInfo(products)
    //     // store query for later so we can fetch next page if needed
    //     this.productQuery = products
    //     this.isLoading = false // turn off loading spinner
    //   })
    // },
    loadMoreChildren: function () {
      this.isLoading = true // turn on loading spinner
      if (this.pageInfo.hasNextPage) {
        this.cursor = this.pageInfo.endCursor
        this.getAllProductTags()
          .then((tags) => {
            console.log('All Product Tags:', tags)
          })
          .catch((error) => {
            console.error('Error:', error)
          })
      }
      // this.client.fetchNextPage(this.productQuery).then((results) => {
      //   if (results.model.length > 0) {
      //     this.parseChildInfo(results.model)
      //     // update stored query to get next page if needed
      //     this.productQuery = results.model[results.model.length - 1] // get next page will start from last child next time
      //   }
      //    // turn off loading spinner
      // })
      this.isLoading = false
    },
    parseChildInfo: function (newChildArray) {
      // parse the query results into an array of children with info
      newChildArray.forEach((product) => {
        var childToAdd = {} // collect child properties in a new dictionary
        // split each tag into a key:value pair and create a property on the child object to store it
        product.node.tags.forEach((tag) => {
          var tagParts = tag.split(':')
          childToAdd[tagParts[0]] = tagParts[1] // add each tag as a property of the child object
        })
        // select appropriate month name
        if (this.languageCode === 'fr') {
          childToAdd.birthdayMonth = this.monthNames.fr[childToAdd.month]
        } else {
          childToAdd.birthdayMonth = this.monthNames.en[childToAdd.month]
        }
        // select appropriate gender noun based on existing gender code in correct language
        childToAdd.genderCode = childToAdd.gender != null ? childToAdd.gender.toLowerCase() : 'f'
        childToAdd.gender = this.genderNames[childToAdd.genderCode][this.languageCode]
        // add picture from product level to the child (not included in tags)
        childToAdd.image = product.node.images.edges[0].node.src || ''
        // check for country names that are too long and shorten them
        switch (childToAdd.country) {
          case 'Democratic Republic of Congo':
            childToAdd.country = 'D.R. Congo'
            break
          case 'République démocratique du Congo':
            childToAdd.country = 'R.D. du Congo'
            break
          case 'Dominican Republic':
            childToAdd.country = 'Dominican Rep.'
            break
          case 'Jerusalem West Bank Gaza':
            childToAdd.country = 'West Bank'
            break
          default:
            break
        }
        // add the child object to the list of children (this.children)
        this.children.push(childToAdd)
      })
    },
    updateSliderSize: function () {
      // update slider size based on div size according to size class UNLESS overridden by HTML params
      let slidesPerView = this.sliderConfigs.defaultSlidesPerView
      let spaceBetweenSlides = this.sliderConfigs.defaultSpaceBetweenSlides
      // wait till page is loaded to get the element
      let classNames = document.getElementById('app').classList

      if (this.overrideSlidesPerView === 0) {
        if (classNames.contains('medsm')) {
          slidesPerView = 2
          if (classNames.contains('sm')) {
            slidesPerView = 1
          }
        }
      } else {
        slidesPerView = this.overrideSlidesPerView
      }

      if (this.overrideSpaceBetweenSlides === 0) {
        if (classNames.contains('medlg')) {
          spaceBetweenSlides = 80
          if (classNames.contains('med')) {
            spaceBetweenSlides = 45
            if (classNames.contains('medsm')) {
              spaceBetweenSlides = 30
              if (classNames.contains('smlg')) {
                spaceBetweenSlides = 20
                if (classNames.contains('xsm')) {
                  spaceBetweenSlides = 10
                }
              }
            }
          }
        }
      } else {
        spaceBetweenSlides = this.overrideSpaceBetweenSlides
      }

      this.sliderConfigs.slidesPerView = slidesPerView
      this.sliderConfigs.spaceBetweenSlides = spaceBetweenSlides
      this.sliderConfigured = true // wait till this resizing is done before showing slider
      this.$refs.slider.updateSlider() // call method to update slider size on child
    }
  }
}
</script>

<style lang="scss">
#app {
  font-size: 16px;
  font-family: "Gill Sans W01 Book", "Gill Sans MT", Calibri, Arial, Trebuchet, sans-serif;
  line-height: 1;
  color: #333333;
  position: relative;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.3;
    font-family: "Gill Sans W01 Book", "Gill Sans MT", Calibri, Arial, Trebuchet, sans-serif;
    font-weight: normal;
    color: #333333;
    text-rendering: optimizeLegibility;
    margin: 0;
  }

  #slider {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  .swiper-button-prev {
    margin-left: 1.5% !important;
    left: 0 !important;
  }

  .swiper-button-next {
    margin-right: 1.5% !important;
    right: 0 !important;
  }

  .swiper-button-prev,
  .swiper-button-next {
    height: 100% !important;
    top: 2% !important;
    width: 120px !important;
  }

  #no-results {
    margin: auto;
  }
}
</style>
<style lang="scss">
#app {
  font-family: "Gill Sans W01 Book", "Gill Sans MT", Calibri, Arial, Trebuchet, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#slider {
  margin-top: 1.5em;
}

/* 3840 x 2160 */
#app.xxlg {
  .swiper-button-prev {
    margin-left: 22% !important;
  }

  .swiper-button-next {
    margin-right: 22% !important;
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 250px !important;
  }
}

/* 2560 x 1440p */
#app.xlg {
  .swiper-button-prev {
    margin-left: 2% !important;
  }

  .swiper-button-next {
    margin-right: 2% !important;
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 300px !important;
  }
}

/* 1920 x 1080 */
#app.lg {
  .swiper-button-prev {
    margin-left: 1.5% !important;
  }

  .swiper-button-next {
    margin-right: 1.5% !important;
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 120px !important;
  }
}

/* 1600 -> 1900 width */
#app.lgsm {
  .swiper-button-prev {
    margin-left: 3% !important;
  }

  .swiper-button-next {
    margin-right: 3% !important;
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 60px !important;
  }
}

/* 1600 x 900 */
#app.medlg {
  .swiper-button-prev {
    margin-left: 4% !important;
  }

  .swiper-button-next {
    margin-right: 4% !important;
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 80px !important;
  }
}

/* 1280x720 and 1366 x 768 */

/* 1024 x 768 */
#app.medsm {
  .swiper-button-prev {
    margin-left: 0% !important;
  }

  .swiper-button-next {
    margin-right: 0% !important;
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 42px !important;
  }

  /* landscape mobile */
  @media screen and (pointer: coarse) and (orientation: landscape) {
    #slider {
      margin-top: 0 !important;
    }

    .swiper-button-prev {
      margin-left: 0.5% !important;
    }

    .swiper-button-next {
      margin-right: 0.5% !important;
    }

    // .swiper-button-prev, .swiper-button-next {
    //   width: 10% !important;
    // }
  }
}

/* 768 x 1024 (iPad) */
#app.smlg {

  /* iPad portrait */
  @media screen and (pointer: coarse) and (orientation: portrait) {
    .swiper-button-prev {
      margin-left: 3% !important;
    }

    .swiper-button-next {
      margin-right: 3% !important;
    }

    .swiper-button-prev,
    .swiper-button-next {
      width: 4% !important;
    }
  }

  /* landscape mobile */
  @media screen and (pointer: coarse) and (orientation: landscape) {
    .swiper-button-prev {
      margin-left: 0% !important;
    }

    .swiper-button-next {
      margin-right: 0% !important;
    }

    .swiper-button-prev,
    .swiper-button-next {
      width: 6% !important;
    }
  }
}

/* 400 -> 600px width */
#app.sm {
  .swiper-button-prev {
    margin-left: 3% !important;
  }

  .swiper-button-next {
    margin-right: 3% !important;
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 40px !important;
  }

  /* landscape mobile */
  @media screen and (pointer: coarse) and (orientation: landscape) {
    .swiper-button-prev {
      margin-left: 2% !important;
    }

    .swiper-button-next {
      margin-right: 2% !important;
    }

    .swiper-button-prev,
    .swiper-button-next {
      width: 10% !important;
    }
  }
}

/* under 400px width */
#app.xsm {
  .icon-info-info {
    font-size: 13px !important;
    letter-spacing: 1px;
    max-width: 150px;
  }

  .swiper-button-prev {
    margin-left: -8px !important;
  }

  .swiper-button-next {
    margin-right: -8px !important;
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 32px !important;
  }
}

// fix for small displays and xsm sizing
@media only screen and (max-width: 599px) {
  #app.xsm .swiper-button-next {
    margin-right: 20px !important;
    margin-top: -155px;
  }

  #app.xsm .swiper-button-prev {
    margin-left: 24px !important;
    margin-top: -155px;
  }

  .sponsorship-card-inner {
    border: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }

  .sponsorship-card-inner:hover {
    border: none !important;
  }
}</style>
