<template>
  <v-card class="slider-background">
    <v-responsive>
      <!-- swiper -->
      <swiper v-if="sliderConfigured" ref="sponsorshipSlider" :key="swiperOptions.spaceBetween" :options="swiperOptions" @reachEnd="$emit('loadMoreChildren')">
        <circle5 v-if="isLoading" id="loader"></circle5>
        <h3 id="no-results" v-if="!isLoading && (children.length === 0)">No results found.</h3>
        <child-card v-for="child in children" :key="child.childid" :child="child" :motCode="motCode" :utmCode="utmCode" :languageCode="languageCode">
        </child-card>
      </swiper>
    </v-responsive>
  </v-card>
</template>

<script>
import ChildCard from './ChildCard.vue'
import { Circle5 } from 'vue-loading-spinner'

export default {
  name: 'DonationSlider',
  components: {
    ChildCard,
    Circle5
  },
  props: ['children', 'motCode', 'utmCode', 'isLoading', 'sliderConfigs', 'sliderConfigured', 'languageCode'],
  data () {
    return {
      swiperOptions: {
        grabCursor: true,
        centeredSlides: false,
        slidesPerView: 3,
        initialSlide: 1,
        slideToClickedSlide: false, // does not work on mobile
        spaceBetween: 180,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  methods: {
    updateSlider: function () {
      // update the slider based on our new config params
      this.swiperOptions.slidesPerView = this.sliderConfigs.slidesPerView
      this.swiperOptions.spaceBetween = this.sliderConfigs.spaceBetweenSlides
    }
  },
  computed: {
    swiper: function () {
      return this.$refs.sponsorshipSlider.swiper
    }
  }
}
</script>

<style lang="scss">
  #loader {
    margin-top: 15%;
    margin-left: 48%;
    z-index: 9
  }
  #no-results {
    margin-top: 16%;
    margin-left: 45%;
    z-index: 9;
    color: #4296fd;
    font-size: 20px;
  }
  .swiper-container {
    max-width: 1600px;
  }
  .v-card .slider-background {
    height: 100%;
    border: none !important;
  }
  /* 3840 x 2160 */
  .xxlg {
    .sponsorshipSlider {
      .swiper-container {
        max-width: 2560px;
      }
    }
  }
  /* 2560 x 1440 */
  .xlg {
    .swiper-container {
      max-width: 1920px;
    }
  }
  /* 1920 x 1080 */
  .lg {
    .swiper-container {
      max-width: 1600px;
    }
  }
  /* 1600 x 900 */
  .medlg {
    .swiper-container {
      max-width: 1200px;
    }
  }
  /* 1300 x 760 */
  .med {
    .swiper-container {
      max-width: 1024px;
    }
  }
  /* 1024 x 768 */
  .medsm {
    .swiper-container {
      max-width: 800px;
    }
  }
  .smlg {
    .swiper-container {
      max-width: 600px;
    }
  }
  /* 600px mobile */
  .sm {
    #loader {
      margin-top: 75%;
      margin-left: 45%;
      z-index: 9
    }
    #no-results {
      margin-top: 68%;
      margin-left: 27%;
      font-size: 20px;
    }
    .swiper-container {
      max-width: 400px;
      height: 500px;
    }
    .swiper-slide {
      height: 400px;
      text-align: center;
      font-size: 18px ;
    }
    /* CSS fix for horizontal phone */
    @media screen and (pointer: coarse) and (orientation: landscape) {
      #loader {
        margin-top: 20%;
      }
    }
  }
  /* 400px mobile */
  .xsm {
    .swiper-container {
      max-width: 300px !important;
      height: 530px;
    }
  }
</style>
